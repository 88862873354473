import getNextUnplayedTrack from './helpers/getNextUnplayedTrack';
import MarkAsPlayedToggle from './MarkAsPlayedToggle';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerState from 'web-player/PlayerState';
import { connect, useDispatch } from 'react-redux';
import { createRadio } from 'shims/playbackActions';
import { createStructuredSelector } from 'reselect';
import { Episode } from 'state/Podcast/types';
import { get } from 'lodash-es';
import { getCurrentPodcastEpisodes } from 'state/Podcast/selectors';
import { getIsLoggedOut } from 'state/Session/selectors';
import { getStationSoftgate } from 'state/Config/selectors';
import { IGetTranslateFunctionResponse, localize } from 'redux-i18n';
import { openSignupModal } from 'state/UI/actions';
import { State } from 'state/types';
import { STATION_TYPE } from 'constants/stationTypes';
import { StationSoftgate } from 'state/Config/types';
import { updatePodcastPlayProgress } from 'state/Podcast/actions';

const openSignup = () => openSignupModal({ context: 'reg-gate' });

type Props = {
  duration: number;
  episodes: Array<Episode>;
  isLoggedOut: boolean;
  secondsPlayed: number;
  seedId: number | string;
  stationSoftgate: StationSoftgate;
  trackId: number;
  translate: IGetTranslateFunctionResponse;
  updateEpisodeProgress: (
    duration: number,
    seedId: string | number,
    trackId: number,
  ) => void;
};

const MarkAsPlayedToggleWrapper = ({
  duration,
  episodes,
  isLoggedOut,
  secondsPlayed,
  seedId,
  stationSoftgate,
  trackId,
  translate,
  updateEpisodeProgress,
}: Props) => {
  const hasSoftgate = get(stationSoftgate, STATION_TYPE.PODCAST);
  const dispatch = useDispatch();

  const markAsPlayed = () => {
    if (hasSoftgate && isLoggedOut) {
      dispatch(openSignup());
    } else {
      updateEpisodeProgress(duration, seedId, trackId);
      const track = PlayerState.getInstance().getTrack();

      if (track && trackId === track.id) {
        const nextEpisode = getNextUnplayedTrack(episodes, track.id);

        if (nextEpisode) {
          createRadio({
            mediaId: nextEpisode.id,
            playedFrom: PLAYED_FROM.PROF_EPISODE_RAIL_RECS,
            stationId: nextEpisode.podcastId,
            stationType: STATION_TYPE.PODCAST,
          });
        }
      }
    }
  };

  const markAsUnplayed = () => {
    if (hasSoftgate && isLoggedOut) {
      dispatch(openSignup());
    } else {
      updateEpisodeProgress(0, seedId, trackId);
    }
  };

  // 0.9 instead of 1 because of the chunk size and the fact that the progress is updated
  // at the beginning and not end of playing a chunk
  const trackHasBeenPlayed = secondsPlayed / duration > 0.9;

  return (
    <MarkAsPlayedToggle
      markAsPlayed={markAsPlayed}
      markAsUnplayed={markAsUnplayed}
      trackHasBeenPlayed={trackHasBeenPlayed}
      translate={translate}
    />
  );
};

type StateProps = {
  episodes: Array<Episode>;
  isLoggedOut: boolean;
  stationSoftgate: StationSoftgate;
};

type DispatchProps = Pick<Props, 'updateEpisodeProgress'>;

type OwnProps = Pick<
  Props,
  Exclude<keyof Props, keyof StateProps | keyof DispatchProps>
>;

const mapStateToProps = createStructuredSelector<State, StateProps>({
  episodes: getCurrentPodcastEpisodes,
  isLoggedOut: getIsLoggedOut,
  stationSoftgate: getStationSoftgate,
});

const Connected: React.ComponentType<OwnProps> = connect(mapStateToProps, {
  updateEpisodeProgress: updatePodcastPlayProgress,
})(MarkAsPlayedToggleWrapper);

export default localize<OwnProps>('translate')(Connected);
